import React from "react";
import { useState, useEffect } from "react";
import { client } from "../util/prismic-configuration";

import "./../styles/global.scss";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import FaqPage from "./faq";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import DashboardPage from "./dashboard";
import SettingsPage from "./settings";
import PurchasePage from "./purchase";
import PreviewPage from "./preview";
import ArticlePage from "./article";
import PrivacyPage from "./privacy";
import AuthPage from "./auth";
import { Switch, Route, Router } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { ProvideAuth } from "./../util/auth.js";

// icon from https://gauger.io/fonticon/

function App(props) {
  const [siteData, setSiteData] = useState(null);
  useEffect(() => {
    async function getSiteData() {
      const homepageData = await client.getSingle("homepage");
      setSiteData(homepageData);
    }
    getSiteData();
  }, []);

  return (
    <ProvideAuth>
      <Router>
        <>
          <Navbar
            color="light"
            spaced={true}
            logo="./../images/logos/amylogo.png"
            issues={siteData?.data?.issues}
          />

          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <IndexPage {...props} {...siteData?.data} />}
            />
            <Route exact path="/faq" component={FaqPage} />
            <Route exact path="/pricing" component={PricingPage} />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/dashboard" component={DashboardPage} />
            <Route exact path="/settings/:section" component={SettingsPage} />
            <Route exact path="/purchase/:plan" component={PurchasePage} />
            <Route exact path="/auth/:type" component={AuthPage} />
            <Route exact path="/preview" component={PreviewPage} />
            <Route exact path="/issue/:article" component={ArticlePage} />
            <Route exact path="/article/:article" component={ArticlePage} />
            <Route exact path="/privacy-policy" component={PrivacyPage} />
            <Route
              exact
              path="/firebase-action"
              component={FirebaseActionPage}
            />
            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            color="dark"
            size="medium"
            backgroundImage=""
            backgroundImageOpacity={1}
            description={siteData?.data?.footer_text}
            copyright={siteData?.data?.copyright}
            logo="./../images/logos/amylogo.png"
            issues={siteData?.data?.issues}
            hasFaqPage={siteData?.data?.has_faq_page}
            hasPricingPage={siteData?.data?.has_pricing_page}
            hasAboutPage={siteData?.data?.has_about_page}
            aboutPageURL={siteData?.data?.about_page_url}
            hasBlog={siteData?.data?.has_blog}
            hasSocial={siteData?.data?.has_social}
          />
        </>
      </Router>
    </ProvideAuth>
  );
}

export default App;
