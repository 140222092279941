import React from "react";
import { useRouter } from "./../util/router.js";
import { linkResolver } from "../util/prismic-configuration";
import HeroSection2 from "./../components/HeroSection2";
import { RichText } from "prismic-reactjs";
import CtaSection from "./../components/CtaSection";

const PrismicArticle = ({
  title,
  subtitle,
  body,
  rich_text,
  hero_colour_class = "primary",
  hero_image = { hero_image_url: null },
  cta_button_text,
  cta_text,
}) => {
  const router = useRouter();
  const { url: hero_image_url } = hero_image;

  return (
    <>
      <HeroSection2
        color={hero_colour_class}
        size="large"
        backgroundImage={hero_image_url}
        backgroundImageOpacity={0.5}
        title={title}
        subtitle={subtitle}
      />
      <section className="is-block">
        <div className="container">
          <div className="content is-medium pt-5 pb-5 mgh-large mx-5">
            {rich_text ? (
              <RichText render={rich_text} linkResolver={linkResolver} />
            ) : null}
            {body?.map(({ slice_type, primary }, index) => {
              switch (slice_type) {
                case "text":
                  return (
                    <RichText
                      key={`text_${index}`}
                      render={primary.text}
                      linkResolver={linkResolver}
                    />
                  );
                case "quote":
                  return (
                    <blockquote key={`quote${index}`}>
                      {primary.quote[0].text}
                    </blockquote>
                  );
                case "image":
                  return (
                    <img
                      key={`image_${index}`}
                      src={primary.article_image.url}
                      alt={primary.article_image.alt}
                    ></img>
                  );
                default:
                  return slice_type;
              }
            })}
          </div>
        </div>
      </section>
      <CtaSection
        color="primary"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title={cta_text ? cta_text : "Ready to get started?"}
        buttonText={cta_button_text ? cta_button_text : "Contact Me"}
        buttonOnClick={() => {
          router.push("/contact");
        }}
      />
    </>
  );
};

export default PrismicArticle;
