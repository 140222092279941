import Prismic from "prismic-javascript";

const apiEndpoint = process.env.REACT_APP_PRISMIC_API_ENDPOINT;
export const client = Prismic.client(apiEndpoint);

export const linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === "article") {
    return `/article/${doc.uid}`;
  }

  if (doc.type === "homepage") {
    return "/";
  }

  if (doc.type === "about") {
    return "/about";
  }

  if (doc.type === "privacy-policy") {
    return "/privacy-policy";
  }

  if (doc.type === "contact") {
    return "/contact";
  }

  if (doc.type === "pricing_page") {
    return "/pricing";
  }
  console.log(doc.type);
  // Backup for all other types
  return "/";
};

export const getRelatedArticleLink = (title, related_article_uid) => {
  const hasPage = !!related_article_uid;
  const slug = related_article_uid
    ? related_article_uid
    : title.replace(/ /g, "_").toLowerCase();
  return { hasPage, slug };
};
