import React from "react";
import { useState, useEffect } from "react";
import { client } from "../util/prismic-configuration";
import ContactSection from "./../components/ContactSection";

function ContactPage(props) {
  const [contactDetails, setContactDetails] = useState(null);
  useEffect(() => {
    async function getContactDetails() {
      const contactData = await client.getSingle("contact");
      setContactDetails(contactData);
    }
    getContactDetails();
  }, []);

  return (
    <>
      {contactDetails?.data && (
        <ContactSection
          color="white"
          size="medium"
          backgroundImage=""
          backgroundImageOpacity={1}
          showNameField={true}
          {...contactDetails?.data}
        />
      )}{" "}
    </>
  );
}

export default ContactPage;
