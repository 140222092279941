import { useEffect } from "react";
import { linkResolver, client } from "../util/prismic-configuration";

const Preview = ({ history, location }) => {
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const documentId = searchParams.get("documentId");

  useEffect(() => {
    if (!token) {
      return console.warn(`No token available, check your configuration`);
    }
    client
      .getPreviewResolver(token, documentId)
      .resolve(linkResolver, "/")
      .then((url) => history.push(url));
  });
  return null;
};

export default Preview;
