import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Contact from "./Contact";
import "./ContactSection.scss";

function ContactSection(props) {
  const {
    color,
    size,
    backgroundImage,
    backgroundImageOpacity,
    title,
    subtitle,
    button_text,
    phone_number,
    email_address,
  } = props;
  return (
    <Section
      color={color}
      size={size}
      backgroundImage={backgroundImage}
      backgroundImageOpacity={backgroundImageOpacity}
    >
      <SectionHeader
        title={title}
        subtitle={subtitle}
        size={3}
        spaced={true}
        className="has-text-centered"
      />
      <div className="ContactSection__container container">
        <div className="card">
          {phone_number && (
            <div className="block pl-4 pt-4">
              <span>
                Phone <a href={`tel:${phone_number}`}>{phone_number}</a>
              </span>
            </div>
          )}
          {email_address && (
            <div className="block pl-4">
              <span>
                Email <a href={`mailto:${email_address}`}>{email_address}</a>
              </span>
            </div>
          )}
          <div className="card-content">
            <Contact
              parentColor={props.parentColor}
              showNameField={props.showNameField}
              buttonText={button_text}
            />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default ContactSection;
