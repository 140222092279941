import React from "react";
import HeroSection from "./../components/HeroSection";
import IssuesSection from "../components/IssuesSection";
import HypnotherapyExplainer from "../components/HypnotherapyExplainer";
import QualificationsSection from "./../components/QualificationsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import CtaSection from "./../components/CtaSection";
import NewsletterSection from "./../components/NewsletterSection";
import { useRouter } from "./../util/router.js";

function IndexPage(props) {
  const router = useRouter();

  const {
    hero_image,
    hero_title,
    hero_subtitle,
    issues_title,
    issues_subtitle,
    issues,
    explainer_title,
    explainer_subtitle,
    explainer_article_link,
    qualifications_title,
    qualifications_subtitle,
    qualifications,
    testimonials_title,
    testimonials_subtitle,
    testimonials,
    has_mailing_list,
    mailing_list_title,
    mailing_list_subtitle,
    mailing_list_field_placeholder,
    mailing_list_button_text,
    mailing_list_subscribed_message,
  } = props;

  return (
    <>
      <HeroSection
        color="primary"
        size="large"
        backgroundImage={hero_image?.url}
        backgroundImageOpacity={0.5}
        title={hero_title}
        subtitle={hero_subtitle}
        buttonText="Contact for Consultation"
        buttonOnClick={() => {
          router.push("/contact");
        }}
      />
      <IssuesSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title={issues_title}
        subtitle={issues_subtitle}
        issues={issues}
      />
      <HypnotherapyExplainer
        color="primary"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title={explainer_title}
        subtitle={explainer_subtitle}
        buttonText="Find out more"
        buttonOnClick={() => {
          router.push(`/article/${explainer_article_link.uid}`);
        }}
      />
      <QualificationsSection
        color="light"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title={qualifications_title}
        subtitle={qualifications_subtitle}
        qualifications={qualifications}
      />
      <TestimonialsSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title={testimonials_title}
        subtitle={testimonials_subtitle}
        testimonials={testimonials}
      />
      <CtaSection
        color="primary"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Ready to get started?"
        buttonText="Contact Me"
        buttonOnClick={() => {
          router.push("/contact");
        }}
      />
      {has_mailing_list && (
        <NewsletterSection
          color="light"
          size="medium"
          backgroundImage=""
          backgroundImageOpacity={1}
          title={mailing_list_title || "Stay in the know"}
          subtitle={
            mailing_list_subtitle || "Receive my latest articles and news"
          }
          buttonText={mailing_list_button_text || "Subscribe"}
          inputPlaceholder={
            mailing_list_field_placeholder || "Enter your email"
          }
          subscribedMessage={
            mailing_list_subscribed_message || "Thank you for subscribing"
          }
        />
      )}
    </>
  );
}

export default IndexPage;
