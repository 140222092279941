import React, { useState } from "react";
import NavbarContainer from "./NavbarContainer";
import { HashLink as Link } from "react-router-hash-link";
import { useAuth } from "./../util/auth.js";
import { getRelatedArticleLink } from "./../util/prismic-configuration";

function Navbar(props) {
  const auth = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const { issues } = props;

  return (
    <NavbarContainer spaced={props.spaced} color={props.color}>
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/">
              <div className="level">
                <img className="level-item mb-0" src={props.logo} alt="Logo" />
                <span className="level-item pl-2 is-size-4 is-hidden-mobile has-text-dark has-text-weight-light">
                  Moray Coast Hypnotherapy
                </span>
              </div>
            </Link>
          </div>
          <div
            className={"navbar-burger burger" + (menuOpen ? " is-active" : "")}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <Link className="navbar-link navbar-item" to="#programmes">
                Help with
              </Link>
              <div className="navbar-dropdown is-boxed">
                {issues
                  ? issues.map(({ title, related_article }) => {
                      const { hasPage, slug } = getRelatedArticleLink(
                        title,
                        related_article.uid
                      );

                      const linkTo = hasPage ? `/issue/${slug}` : `/#${slug}`;
                      return (
                        <Link
                          smooth
                          className="navbar-item"
                          key={linkTo}
                          to={linkTo}
                        >
                          {title}
                        </Link>
                      );
                    })
                  : "loading"}
              </div>
            </div>
            <hr className="dropdown-divider" />
            {auth.user && (
              <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link" to="/">
                  Account
                </Link>
                <div className="navbar-dropdown is-boxed">
                  <Link className="navbar-item" to="/dashboard">
                    Dashboard
                  </Link>
                  <Link className="navbar-item" to="/settings/general">
                    Settings
                  </Link>
                  <hr className="dropdown-divider" />
                  <Link
                    className="navbar-item"
                    to="/auth/signout"
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign out
                  </Link>
                </div>
              </div>
            )}

            {/* {!auth.user && (
              <Link className="navbar-item" to="/auth/signin">
                Sign in
              </Link>
            )} */}
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
}

export default Navbar;
