import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";

function HypnotherapyExplainer({
  color,
  size,
  backgroundImage,
  backgroundImageOpacity,
  title,
  subtitle,
  buttonText,
  buttonOnClick,
}) {
  return (
    <Section
      color={color}
      size={size}
      backgroundImage={backgroundImage}
      backgroundImageOpacity={backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={title}
          subtitle={subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        />
        {buttonText && buttonOnClick && (
          <div className="buttons is-centered">
            <SectionButton
              parentColor={color}
              size="medium"
              onClick={buttonOnClick}
            >
              {buttonText}
            </SectionButton>
          </div>
        )}
      </div>
    </Section>
  );
}

export default HypnotherapyExplainer;
