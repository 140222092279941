import React from "react";
import "./Issues.scss";
import { useRouter } from "../util/router.js";
import { getRelatedArticleLink } from "./../util/prismic-configuration";

function Issues(props) {
  const router = useRouter();
  const { issues } = props;

  return (
    <div className="Issues columns is-multiline is-centered is-gapless">
      {issues
        ? issues.map(({ title, subtitle, image, related_article }) => {
            const { hasPage, slug } = getRelatedArticleLink(
              title,
              related_article.uid
            );
            const style = {
              width: image.dimensions.width,
              height: image.dimensions.height,
            };

            return (
              <div
                className={
                  "Issues__column column" +
                  (props.columns === 1 ? " is-full" : "") +
                  (props.columns === 2 ? " is-half" : "") +
                  (props.columns === 3 ? " is-one-third" : "") +
                  (props.columns === 4 ? " is-one-quarter" : "")
                }
                key={slug}
              >
                <div id={slug} className="Issues__item has-text-centered">
                  <div className="Issues__image-container">
                    <figure
                      className={`Issues__image image is-${props.imageRatio}`}
                      style={style}
                    >
                      <img src={image.url} alt={title} />
                    </figure>
                  </div>
                  {hasPage ? (
                    <button
                      className="button title is-4 is-spaced has-text-dark"
                      onClick={() => {
                        router.push(`/issue/${slug}`);
                      }}
                    >
                      {title}
                    </button>
                  ) : (
                    <h1 className="title is-4 is-spaced has-text-dark">
                      {title}
                    </h1>
                  )}
                  <h2 className="subtitle is-6 has-text-dark">{subtitle}</h2>
                </div>
              </div>
            );
          })
        : "Loading Items"}
    </div>
  );
}

export default Issues;
