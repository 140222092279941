import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Issues from "./Issues";
import "./IssuesSection.scss";

function IssuesSection(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        />
        <div className="IssuesSection__box box">
          <Issues columns={2} imageRatio="4by3" issues={props.issues} />
        </div>
      </div>
    </Section>
  );
}

export default IssuesSection;
