import React from "react";
import PricingSection from "./../components/PricingSection";
import { useState, useEffect } from "react";
import { client } from "../util/prismic-configuration";

function PricingPage(props) {
  const [pricingDetails, setPricingDetails] = useState(null);
  useEffect(() => {
    async function getPricingDetails() {
      const pricingData = await client.getSingle("pricing_page");
      setPricingDetails(pricingData);
    }
    getPricingDetails();
  }, []);

  return (
    <PricingSection
      color="white"
      size="medium"
      backgroundImage=""
      backgroundImageOpacity={1}
      {...pricingDetails?.data}
    />
  );
}

export default PricingPage;
