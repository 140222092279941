import React from "react";
import { useState, useEffect } from "react";
import { useRouter } from "../util/router.js";
import { client } from "../util/prismic-configuration";
import PrismicArticle from "../components/PrismicArticle";

const Article = (props) => {
  const router = useRouter();
  const articleId = router.query.article;

  const [doc, setDocData] = useState(null);
  useEffect(() => {
    async function getDocument(articleId) {
      const document = await client.getByUID("article", articleId);
      setDocData(document);
    }
    getDocument(articleId);
  });

  return (
    <>{doc ? <PrismicArticle {...doc.data}></PrismicArticle> : "loading..."}</>
  );
};

export default Article;
