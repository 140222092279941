import React from "react";
import { useRouter } from "./../util/router.js";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Pricing from "./Pricing";
import CtaSection from "./../components/CtaSection";

function PricingSection(props) {
  const {
    title,
    subtitle,
    contact_cta_text,
    contact_cta_button_text,
    body,
  } = props;

  const pricingOptions = body?.filter(
    ({ slice_type }) => slice_type === "pricing_option"
  );

  const router = useRouter();

  return (
    <>
      <Section
        id="pricing"
        color={props.color}
        size={props.size}
        backgroundImage={props.backgroundImage}
        backgroundImageOpacity={props.backgroundImageOpacity}
      >
        <div className="container">
          <SectionHeader
            title={title}
            subtitle={subtitle}
            size={3}
            spaced={true}
            className="has-text-centered"
          />
          {pricingOptions && <Pricing items={pricingOptions} />}
        </div>
      </Section>
      <CtaSection
        color="primary"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title={
          contact_cta_text
            ? contact_cta_text
            : "Get started or discuss any special requirements"
        }
        buttonText={
          contact_cta_button_text ? contact_cta_button_text : "Contact Me"
        }
        buttonOnClick={() => {
          router.push("/contact");
        }}
      />
    </>
  );
}

export default PricingSection;
