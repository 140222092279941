import React from "react";
import CenteredColumns from "./CenteredColumns";
import Avatar from "./Avatar";
import "./Testimonials.scss";

function Testimonials(props) {
  const { testimonials } = props;
  return (
    <CenteredColumns>
      {testimonials?.map((testimonial) => (
        <div className="column" key={testimonial.text.substring(0, 8)}>
          <figure className="testimonial">
            <blockquote className="Testimonials__card card">
              "{testimonial.text}"
            </blockquote>
            <div className="Testimonials__author">
              <div className="Testimonials__avatar-wrapper">
                <Avatar
                  image={testimonial.issue_ident.url}
                  size={64}
                  alt={testimonial.issue_name}
                />
              </div>
              <div className="Testimonials__info">
                <div className="has-text-weight-bold has-text-dark">
                  {testimonial.name}
                </div>
                <div className="Testimonials__company is-size-7">
                  {testimonial.issue_text}
                </div>
              </div>
            </div>
          </figure>
        </div>
      ))}
    </CenteredColumns>
  );
}

export default Testimonials;
