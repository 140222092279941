import React from "react";
import { useState, useEffect } from "react";
import { client } from "../util/prismic-configuration";
import PrismicArticle from "../components/PrismicArticle";

function Privacy() {
  const [privacyContent, setPrivacyContent] = useState(null);
  useEffect(() => {
    async function getPrivacyContent() {
      const privacyData = await client.getSingle("privacy-policy");
      setPrivacyContent(privacyData);
    }
    getPrivacyContent();
  }, []);

  return (
    <>
      {privacyContent && privacyContent?.data?.rich_text ? (
        <PrismicArticle {...privacyContent.data}></PrismicArticle>
      ) : privacyContent ? (
        "Awaiting published content"
      ) : (
        "loading..."
      )}
    </>
  );
}

export default Privacy;
