import React from "react";
import Section from "./Section";
import { HashLink as Link } from "react-router-hash-link";
import { getRelatedArticleLink } from "./../util/prismic-configuration";
import "./Footer.scss";

function Footer(props) {
  const {
    issues = [],
    hasAboutPage = null,
    aboutPageURL = null,
    hasPricingPage = null,
    hasFaqPage = null,
    hasBlog = null,
    hasSocial = null,
  } = props;
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
      className="footer"
    >
      <div className="FooterComponent__container container">
        <div className="columns">
          <div className="column is-3 is-3-widescreen">
            <Link className="brand" to="/">
              <div className="brand-icon">
                <img
                  className="FooterComponent__logo"
                  src={props.logo}
                  alt="Logo"
                />
              </div>
            </Link>

            {props.description && (
              <p className="FooterComponent__description">
                {props.description}
              </p>
            )}

            {props.copyright && (
              <p className="FooterComponent__copyright">{props.copyright}</p>
            )}
          </div>
          <div className="column is-9 is-7-widescreen is-offset-2-widescreen">
            <div className="columns">
              <div className="column is-3">
                <div className="menu">
                  <p className="menu-label">Popular Programmes</p>
                  <ul className="menu-list">
                    {issues?.map(({ title, related_article }) => {
                      const { hasPage, slug } = getRelatedArticleLink(
                        title,
                        related_article.uid
                      );
                      const linkTo = hasPage ? `/issue/${slug}` : `/#${slug}`;
                      return (
                        <li key={linkTo}>
                          <Link smooth to={linkTo}>
                            {title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="column is-3">
                <div className="menu">
                  <p className="menu-label">Information</p>
                  <ul className="menu-list">
                    {hasPricingPage && (
                      <li>
                        <Link to="/pricing">Pricing</Link>
                      </li>
                    )}
                    {hasFaqPage && (
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>
                    )}
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column is-3">
                <div className="menu">
                  <p className="menu-label">Me</p>
                  <ul className="menu-list">
                    {hasAboutPage && (
                      <li>
                        <Link to={`/article/${aboutPageURL.uid}`}>About</Link>
                      </li>
                    )}
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    {hasBlog && (
                      <li>
                        <a
                          target="_blank"
                          href="https://medium.com"
                          rel="noopener noreferrer"
                        >
                          Blog
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              {hasSocial && (
                <div className="column is-3">
                  <div className="menu">
                    <p className="menu-label">Social</p>
                    <ul className="menu-list">
                      <li>
                        <a
                          href="https://www.facebook.com/profile.php?id=100090063160359"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img className="image is-24x24"
                            src="https://uploads.divjoy.com/icon-facebook.svg"
                            alt="Facebook"
                          />
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://uk.linkedin.com/in/amy-jackson-24460650"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img className="image is-24x24"
                            src="https://divjoy.com/static/images/icons/linkedin.svg"
                            alt="LinkedIn"
                          />
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Footer;
