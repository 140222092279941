import React from "react";
import "./Qualifications.scss";

function Qualifications(props) {
  const { qualifications } = props;

  return (
    <div className="columns is-centered is-multiline">
      {qualifications?.map(({ qualification_logo }) => (
        <div
          className="column is-narrow has-text-centered"
          key={qualification_logo.alt}
        >
          <div className="Qualifications__logo">
            <img
              src={qualification_logo.url}
              width={qualification_logo.dimensions.width}
              alt={qualification_logo.alt}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Qualifications;
